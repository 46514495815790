html {
  /* Typography */
  --system-font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --header-font: 'co-headline', var(--system-font-stack);
  --font-size-small: 0.875rem;
  --line-height: 1.5556;
  --vertical-rhythm: calc(1rem * var(--line-height));
  /* Layout */
  --wrapper-padding-x: 1rem;
  --wrapper: calc(40rem + (2 * var(--wrapper-padding-x)));
  --wrapper-wide: calc(80rem + (2 * var(--wrapper-padding-x)));
  --gap: calc(2 * var(--vertical-rhythm));
  /* Header */
  --header-height: 5.75rem;
}

@media(min-width:768px) {
  html {
    /* Layout */
    --wrapper-padding-x: 2rem;
  }
}