/* Typefaces */
.h1 {
  @apply font-header font-bold text-5xl sm:text-7xl;
}
.h2 {
  @apply font-header font-bold text-3xl sm:text-5xl;
}
.h3 {
  @apply font-header font-bold text-xl leading-none;
}
.h4 {
  @apply font-header leading-none tracking-wide;
}
.eyebrow {
  @apply text-base text-purple-48 font-header uppercase leading-none tracking-wider;
}
.subtitle {
  @apply font-extralight text-xl sm:text-2xl;
}

/* Buttons */
.button, .button[type="button"], .button[type="submit"] {
  position: relative;
  display: inline-flex;
  align-items: baseline;
  gap: 0.75rem;
  padding: 1rem 2.5rem 1.25rem;
  border-radius: 0.5rem;
  background-image: linear-gradient(145deg, #CE64CE, #BA3BBA);
  font-family: var(--header-font);
  letter-spacing: 0.02em;;
  line-height: 1;
  color: white;
  cursor: pointer;
}
.button:hover {
  filter: brightness(95%);
}
.button:active {
  filter: brightness(87.5%);
}
.button:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.button.button--secondary,
.button.button--secondary:active {
  background-clip: text;
  color: transparent;
}
.button.button--small {
  padding: 0.625rem 1.25rem 0.75rem;
}
