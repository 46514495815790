.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  padding: 1.25rem 0;
}
.header__logo {
  align-self: stretch;
  margin: 0;
}
.header__logo img {
  height: 100%;
}
.header__menu {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.header .header__link {
  margin: 0;
  text-decoration: none;
  color: inherit;
}
.header .header__link[aria-current="page"] {
  opacity: 0.7;
}
.header__hamburger {
  display: none;
}
@media (max-width: 64rem) {
  .header__menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.9);
    color: white;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-out;
  }
  .header__link {
    
  }
  .header--open .header__menu {
    opacity: 1;
    visibility: visible;
  }
  .header__hamburger {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1001;
    display: block;
    border: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: var(--header-height);
    font-size: 1.5rem;
    background: none;
    cursor: pointer;
  }
  .header--open .header__hamburger {
    color: white;
  }
}