.laptop {
  position: relative;
  pointer-events: none;
  user-select: none;
}

.laptop__frame {
  margin: 0;
  width: 100%;
}

.laptop__screen {
  position: absolute;
  top: 2.642%;
  left: 10.30%;
  z-index: -1;
  width: 79.40%;
  background: white;
}

.phone {
  position: relative;
  pointer-events: none;
  user-select: none;
}

.phone__frame {
  margin: 0;
  width: 100%;
}

.phone__screen {
  position: absolute;
  top: 2.871%;
  left: 5.923%;
  z-index: -1;
  width: 88.08%;
  background: white;
}

.devices {
  position: relative;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.devices__phone {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 23%;
}