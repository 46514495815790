@import "./variables.css";
@import "./typography.css";
@import "./header.css";
@import "./devices.css";
@import "./mouse.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
* {
  scroll-padding-top: calc(var(--header-height) + 2rem);
}
img, video {
  max-width: 100%;
}

/* Tailwind's Preflight border reset interferes with hCaptcha's CSS arrow */
body > div:last-child * {
  border-style: revert;
}

.wrapper {
  @apply mx-auto max-w-7xl px-4 sm:px-8;
}

.speechbubble {
  position: relative;
}
/* arrow */
.speechbubble:after {
  content: '';
  position: absolute;
  top: 100%;
  right: 20%;
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 14px solid transparent;
  @apply border-t-purple-92;
  transform: rotate(-5deg) translateY(-7%);
  pointer-events: none;
}
.speechbubble:nth-child(odd):after {
  left: 20%;
  right: auto;
  transform: rotate(5deg) translateY(-7%);
}

/* App store badges */
.store-badge {
  display: inline-block;
}
.store-badge:active {
  filter: brightness(75%);
}
.store-badge img {
  margin: 0;
}